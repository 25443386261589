import React, { useState, useEffect, useMemo } from "react"
import styled from "styled-components"
import Loader from "react-loader-spinner"
import {
  CurrentUserAsync,
  getPatientHistory,
} from "../../services/parse-bridge"
import {
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/admin/table-components"
import Layout from "../../components/layout"
import { navigate } from "gatsby"
import MainButton from "../../components/main-button"
import Search from "../../components/admin/search"
import Fuse from "fuse.js"

const Container = styled.div`
  padding: 50px 20px 0;
  max-width: 1140px;
  margin: 0 auto;
`

const TableView = styled.div`
  overflow-x: auto;
`

const AdminPage = () => {
  const [patientForms, setPatientForms] = useState([])
  const [loading, setLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [searching, setSearching] = useState(false)

  // Is an admin logged in?
  useEffect(() => {
    CurrentUserAsync().then(res => {
      setLoading(false)
      if (res) {
        setLoggedIn(true)
      } else {
        setLoggedIn(false)
        navigate("/admin/login")
      }
    })
  }, [])

  // Get form data
  useEffect(() => {
    getPatientHistoryData()
  }, [])

  const getPatientHistoryData = async () => {
    const historyData = await getPatientHistory()
    setPatientForms(historyData)
  }

  // Handling search
  const options = {
    keys: ["name", "email", "phoneNumber"],
  }

  const fuse = new Fuse(patientForms, options)
  const result = fuse.search(searchValue)

  const searchData = useMemo(() => result.map(item => item.item), [result])
  useEffect(() => {
    if (searchValue === "") {
      setSearching(false)
    } else {
      setSearching(true)
    }
  }, [searchValue])

  if (loading) {
    return <div>Loading</div>
  }

  return (
    <Layout noNav>
      <Container>
        <h1>Patient Registration Forms</h1>
        <MainButton
          style={{ marginBottom: 10 }}
          onClick={() => navigate("/patient-registration")}
        >
          Add new
        </MainButton>
        <Search
          placeholder="Search for a patient by name, email or phone number"
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <TableView style={{ marginTop: 20 }}>
          {!patientForms && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Loader color={"#000"} height={30} width={30} type="Oval" />
            </div>
          )}

          <table>
            <TableHeader>
              <TableHead>Created</TableHead>
              <TableHead>Patient</TableHead>
              <TableHead>Phone</TableHead>
              <TableHead>Email</TableHead>
            </TableHeader>
            <tbody>
              {(searching ? searchData : patientForms)?.map((item, index) => (
                <TableRow
                  onClick={() =>
                    navigate(`/admin/patient?id=${item.id}`, {
                      state: item,
                    })
                  }
                  odd={index % 2 !== 0}
                  key={index}
                >
                  <TableData>{item.createdAt.toDateString()}</TableData>
                  <TableData>{item.name}</TableData>
                  <TableData>{item.phoneNumber ?? "-"}</TableData>
                  <TableData>{item.email ?? "-"}</TableData>
                </TableRow>
              ))}
            </tbody>
          </table>
        </TableView>
      </Container>
    </Layout>
  )
}

export default AdminPage
