import styled from "styled-components"

export const TableHeader = styled.tr`
  border-bottom: 1px solid #000;
`

export const TableHead = styled.th`
  color: #000;
  border-bottom: 0;
  font-weight: 600;
  font-size: 19px;
  font-family: "Articulat";
  white-space: nowrap;
`

export const TableData = styled.td`
  /* color: grey; */
  color: #000;
  font-size: 16px;
  border-bottom: 0;
  line-height: 1.2;
  font-family: "Articulat";
  white-space: nowrap;
  cursor: pointer;
`

export const TableRow = styled.tr`
  background: ${props => (props.odd ? "#f5f5f5" : "white")};
`
