import React from "react"
import styled from "styled-components"

const TextInput = styled.input`
  width: 100%;
  font-family: "Articulat";
  font-size: 16px;
  font-weight: 500;
  padding: 12px;
`

const InputContainer = styled.div`
  position: relative;
  width: 100%;

  & > svg {
    position: absolute;
    height: 25px;
    top: 15px;
    left: 10px;
  }
`
function SearchInput({ placeholder, onChange, value }) {
  return (
    <InputContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
      <TextInput
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        style={{ paddingLeft: "40px" }}
      />
    </InputContainer>
  )
}

export default SearchInput
