import React from "react"
import SearchInput from "./searchInput"

function Search({
  placeholder = "Search for a product by name or category",
  searchValue,
  setSearchValue,
}) {
  const handleSearchChange = ({ target }) => {
    setSearchValue(target.value)
  }
  return (
    <div>
      <SearchInput
        placeholder={placeholder}
        value={searchValue}
        onChange={handleSearchChange}
      />
    </div>
  )
}

export default Search
